import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import {Grid, Col, Row} from 'react-styled-flexboxgrid';
import '../styles/styles.scss';

const Page = props => {
  const post = props.data.markdownRemark;

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />
      <Grid>
        <div className="page">
          <Row>
            <Col sm={8} xs={12}>
              <div className="page__text" dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </Col>
          </Row>
        </div>
      </Grid>
    </>
  );
};

export const query = graphql`
  query Page($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        image
      }
      html
    }
  }
`;

export default Page;
